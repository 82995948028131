import React, { useRef } from "react";
import { Table } from "reactstrap";
import { withRouter } from "react-router-dom";

import ProjectRow from "../../../Projects/Tables/ProjectRow";

const ContractProjectsTable = ({ href, status, contract }) => {

  const isProjectGroupVisible = useRef({});

  const areaOfProjects = {
    ACTIVE: "activeProjects",
    COMPLETED: "completedProjects",
    DRAFT : 'draftProjects',
  }

  const expandProject = (projectId) => {
    return isProjectGroupVisible.current[projectId] = true;
  }

  return (
    <Table
      responsive
      hover
      className="mt-3 table react-table table--bordered pl-0"
    >
      <thead>
        <tr>
          <th>NAME</th>
          <th>BUDGET</th>
          <th>PAID</th>
          <th>DATE</th>
          <th>TERM</th>
        </tr>
      </thead>
      <tbody>
        {
          contract && contract[0] &&
          !!contract[0][areaOfProjects[status]] &&
          !!contract[0][areaOfProjects[status]].length
          ? contract[0][areaOfProjects[status]].map((item, index) => {
              return(
              <ProjectRow
                key={index}
                item={item}
                index={index}
                href={href}
                status={status}
                expandProject={expandProject}
                isProjectGroupVisibleRef={isProjectGroupVisible}
              />
              )
            })
          : <tr><td>No projects found in this category.</td></tr>
          }
      </tbody>
    </Table>
  );
};

export default withRouter(ContractProjectsTable);
