import React, {useEffect, useReducer, useRef, useState} from "react";
import { withRouter } from "react-router-dom";
import {Button, Input, Table} from "reactstrap";
import {
  CalendarIcon,
  CreateCalendarIcon,
  ClockIcon,
  PoundIcon,
  UserIcon, BuildIcon,
} from "../../../constants/icons";
import Moment from "react-moment";
import ImagesCard from "../../Jobs/JobPreview/ImagesCard";
import {addNoteToDayWork, getDayWorkLogs, updateDayWorkById} from "../../../redux/services/day_work";
import { DAY_WORK_STATUS } from "../../../constants/enamerations";
import Loading from "../../../shared/components/Loader";
import DayWorkRejectedForm from "./DayWorkRejectedForm";
import {calculateDayWorkHourCost} from "../../../config/func";
import DayworkHistoryTable from "../../Projects/Tables/DayworkHistory/DayworkHistoryTable";
import InvoiceModal from "../../Invoices/invoiceModal";
import {getInvoiceById} from "../../../redux/services/invoice";
import {toggleDayworkHold} from "../../../redux/api/day_work";
const DayWorkForm = ({
  item,
  moreImageID,
  toggleMoreImage,
  getPhotosByStep,
  setModal,
  getDayWorkRequests,
  setLoadTable,
  showButtons,
}) => {
  const [load, setLoad] = useState(false);
  const [switchModal, setSwitchModal] = useState(1);
  const [dayworkLogsLoading, setDayworkLogsLoading] = useState(false);
  const [dayworkLogs, setDayworkLogs] = useState([]);
  const [invoiceItem, setInvoiceItem] = useState(null);
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
  const [loadInvoiceModal, setLoadInvoiceModal] = useState(false);
  const [,forceUpdate] = useReducer(x => x + 1, 0);

  const noteRef = useRef('');

  const toggleInvoiceModal = () => {
    setInvoiceModalOpen(!invoiceModalOpen);
  };
  const getInvoiceId = (id) => {
    setLoadInvoiceModal(true);
    getInvoiceById(id).then((res) => {
      setInvoiceItem(res);
      setLoadInvoiceModal(false);
    });
  };

  useEffect(() => {
    const fetchDayworkLogs = () => {
      setDayworkLogsLoading(true);
      getDayWorkLogs(item.id).then((res) => {
        if(res && Array.isArray(res)){
          setDayworkLogs(res);
        }
        setDayworkLogsLoading(false);
      });
    };
    fetchDayworkLogs()
  }, []);

  const SubmitNote = async (e) => {
    if (e.key === 'Enter') {
      const res = await addNoteToDayWork(item.id, noteRef.current).then((res) => res);
      item.notes = res.data.updated;
      noteRef.current = '';
      forceUpdate();
    }
  }

  const Approved = () => {
    setLoad(true);
    setLoadTable(true);
    updateDayWorkById(item.id, DAY_WORK_STATUS.APPROVED.value).then(() => {
      setLoad(false);
      setModal(false);
      getDayWorkRequests(() => setLoadTable(false));
    });
  };

  const ToggleHold = () => {
    setLoad(true);
    setLoadTable(true);
    toggleDayworkHold(item.id).then(() => {
      setLoad(false);
      setModal(false);
      getDayWorkRequests(() => setLoadTable(false));
    });
  };

  const Rejected = (data) => {
    setLoad(true);
    setLoadTable(true);
    updateDayWorkById(
      item.id,
      DAY_WORK_STATUS.REJECTED.value,
      data.deny_comment
    ).then(() => {
      setLoad(false);
      setModal(false);
      getDayWorkRequests(() => setLoadTable(false));
    });
  };
  return load ? (
    <Loading />
  ) : switchModal === 1 ? (
    <div>
      <div className="mt-4 d-flex justify-content-around">
        {item && item.levels && (
          <div className="d-flex align-items-center">
            <h3 className="page-subhead subhead m-0">Level &nbsp;</h3>
            <p className="m-0">{item.levels.name}</p>
          </div>
        )}

        {item && item.levels && item.levels.block && (
          <div className="d-flex align-items-center">
            <h3 className="page-subhead subhead m-0">Block &nbsp;</h3>
            <p className="m-0">{item.levels.block.name}</p>
          </div>
        )}
        {item && !!item.location && (
            <div className="d-flex align-items-center">
              <h3 className="page-subhead subhead m-0">Plot &nbsp;</h3>
              <p className="m-0">{item.location}</p>
            </div>
        )}
      </div>
      <div className="hr_wrapper">
        <hr />
      </div>
      <div>
        <p>
          <BuildIcon /> &nbsp;{" "}
          {item.project && item.project.name && <span>{item.project.name}</span>}
        </p>
        <p>
          <CalendarIcon /> &nbsp;{" "}
          <Moment format="LL">{item && item.date_from}</Moment> -{" "}
          <Moment format="LL">{item && item.date_to}</Moment>
        </p>
        <p>
          <ClockIcon /> &nbsp;{" "}
          <Moment parse="HH:mm:ss" format="LT">
            {item && item.start_at}
          </Moment>{" "}
          -{" "}
          <Moment parse="HH:mm:ss" format="LT">
            {item && item.end_at}
          </Moment>
        </p>
        <p className="d-flex align-items-center">
          <PoundIcon />
          <span className="ml-2">{calculateDayWorkHourCost({
            startDate: item.date_from,
            endDate: item.date_to,
            startTime: item.start_at,
            endTime: item.end_at,
            dayworkHour: item.hourly_rate,
            userHourRate:  item.creator && item.creator.hourly_rate ? item.creator.hourly_rate : undefined,
            budget: item.budget}).cost}</span>
          <div className="ml-2">({!!item.budget ? 'Charged with Budget' : 'Charged with Hourly Rate'})</div>
        </p>
        {item && item.creator && (
          <p className="d-flex align-items-center">
            <UserIcon />
            <span className="ml-2">
              {item.creator.first_name}&nbsp;{item.creator.surname}
            </span>
          </p>
        )}
        <p>
          <CreateCalendarIcon /> &nbsp;{" "}
          <Moment format="LL">{item && item.created_at}</Moment>
        </p>
        <p className="d-flex align-items-center">
          {item.status && DAY_WORK_STATUS[item.status].icon} &nbsp;{" "}
          {item.status && DAY_WORK_STATUS[item.status].label}
        </p>
      </div>
      <div className="mt-3 ml-3">
        <ImagesCard
          item={item}
          moreImageID={moreImageID}
          toggleMoreImage={toggleMoreImage}
          getPhotosByStep={getPhotosByStep}
        />
      </div>
      {item.status === DAY_WORK_STATUS.REJECTED.value && item.deny_comment && <div className={"mt-2"}>
        <h5 className={"page-title mb-2"}>Deny Reason:</h5>
        <div className="description_question_wrapper mr-0">
          <p>
            {item.deny_comment}
          </p>
        </div>
      </div>}
      {showButtons ? (
        <>
          <div className="mt-3 d-flex justify-content-end">
            <Button
                className="m-0 mr-3"
                color="secondary"
                onClick={() => ToggleHold()}
            >
              {!item.on_hold ? 'Hold' : 'Resume'}
            </Button>
            {!item.on_hold ? (<Button
              className="m-0 mr-3"
              color="secondary"
              onClick={() => setSwitchModal(2)}
            >
              Deny
            </Button>):''}
            {!item.on_hold ? (<Button className="m-0" color="primary" onClick={() => Approved()}>
              Approve
            </Button>):''}
          </div>
        </>
      ) : null}
      <div>
        <p className="page-subhead mt-3 mb-2">NOTES</p>
        <Input type="text" name="note" placeholder="Leave a note"
               style={{marginTop: 10}}
               ref={noteRef}
               onChange={(e) => noteRef.current = e.currentTarget.value }
               onKeyUp={(e) => {
                 if (e.key === 'Enter') {
                   SubmitNote(e);
                   e.currentTarget.value = '';
                 }}
              }
        />
        {dayworkLogsLoading ? <Loading/> : (!item.notes || item.notes.length === 0) ? <div className="d-flex justify-content-center mt-2">
          <p>No notes added</p>
        </div> : <div>
          <Table
              responsive
              className="table table--bordered pl-0">
            <thead>
            <tr>
              <th>USER</th>
              <th>NOTE</th>
            </tr>
            </thead>
            <tbody>
            {item.notes.split('||%%||').map((note) => (
                <tr>
                  <td width="25%">
                    { note.split('||&&||')[0] }<br/>
                    <span
                      style={{
                        fontSize: '12px',
                        color: '#999999'
                      }}
                    >
                      { note.split('||&&||')[2] }<br/>
                    </span>
                  </td>
                  <td>
                    { note.split('||&&||')[1] }
                  </td>
                </tr>
            ))}
            </tbody>

          </Table>
        </div>}

      </div>
      <div>
        <p className="page-subhead mt-3 mb-2">DAYWORK HISTORY LOG</p>
        {dayworkLogsLoading ? <Loading/> : dayworkLogs.length === 0 ? <div className="d-flex justify-content-center mt-2">
          <p>No daywork logs found</p>
        </div> : <div>
          <DayworkHistoryTable dayworksLogs={dayworkLogs} onInvoicePressed={(invoiceId) => {
            toggleInvoiceModal();
            getInvoiceId(invoiceId)}}
          />
        </div>}

      </div>
      {invoiceModalOpen && (
          <InvoiceModal
              invoiceModal={invoiceModalOpen}
              toggleInvoiceModal={toggleInvoiceModal}
              invoiceItem={invoiceItem}
              loadInvoiceModal={loadInvoiceModal}
          />
      )}
    </div>
  ) : (
    <DayWorkRejectedForm Rejected={Rejected} setModal={setModal} />
  );
};

export default withRouter(DayWorkForm);
