import React from 'react';

const JobImageGallery = ({ job }) => {
    const groupedImages = [];

    // Group images by 4
    if (job && job.questions) {
        const questionsWithAssets = job.questions.filter(
            (question) => question.assets && question.assets.length > 0
        );

        const images = questionsWithAssets.flatMap((question) =>
            question.assets.map((asset) => asset.public_url)
        );

        while (images.length > 0) {
            groupedImages.push(images.splice(0, 4));
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', pageBreakBefore: 'always' }}>
            {groupedImages.map((pageImages, pageIndex) => (
                <div key={pageIndex} style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {pageImages.map((image, index) => (
                        <div key={index} style={{ width: '50%', maxHeight: '790px', padding: '10px' }}>
                            <img
                                src={image}
                                alt={`${index + 1}`}
                                style={{ width: '100%', height: '100%' }}
                            />
                        </div>
                    ))}
                    {pageIndex !== groupedImages.length - 1 && (
                        <hr style={{ margin: '20px 0' }} />
                    )}
                </div>
            ))}
        </div>
    );
};

export default JobImageGallery;
