import React, {useCallback, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import {Col, Row, Container, Card, CardBody, Table, Modal,} from 'reactstrap';
import {getAdditionalWorkRequests} from "../../../redux/services/requests";
import {WORK_TYPE} from "../../../constants/enamerations";
import Loader from "../../../shared/components/Loader";
import Pagination from "@material-ui/lab/Pagination";
import {SortOrderIcon} from "../../../constants/icons";
import AdditionalWorkForm from "../../Jobs/Forms/AdditionalWorkFilterForm";
import {updateRequestStatus} from "../../../redux/services/jobs";
import SearchIcon from "mdi-react/SearchIcon";
import PhotoViewer from "../../../shared/components/PhotoViewer";

const Uplifts = () => {
    const [currentUplifts, setCurrentUplifts] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [totalPage, setTotalPage] = useState(1);
    const [filters, setFilters] = useState({dateOrder: 'ASC', orderBy: 'jobs.id'});
    const [sortFilter, setSortFilter] = useState();
    const [operativeNameFilter, setOperativeNameFilter] = useState('');
    const [projectNameFilter, setProjectNameFilter] = useState('');
    const [projectManagerFilter, setProjectManagerFilter] = useState('');

    const [modal, setModal] = useState(false);
    const [assets, setAssets] = useState(null);
    const [currentRequest, setCurrentRequest] = useState(null);
    const [load, setLoad] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [photos, setPhotos] = useState(null)

    const closeLightbox = () => {
        setViewerIsOpen(false);
        setCurrentImage(0);
    };

    const toggleModal = (item) => {
        if (item.assets) {
            item.assets.forEach(asset => asset.public_url = 'https://job-assets.s3.eu-west-2.amazonaws.com/jobs/' + item.jobs.id + '/additional-work/' + asset.source)
        }
        setAssets(item && item.assets)
        setCurrentRequest(item)
        setModal(!modal)
    }

    const setupPhotoViewer = (item) => {
        let photos = [];
        if (item.assets) {
            item.assets.forEach(asset => {
                photos.push({ source: 'https://job-assets.s3.eu-west-2.amazonaws.com/jobs/' + item.jobs.id + '/additional-work/' + asset.source});
            })
        }
        setPhotos(photos);
    }

    const updateRequest = (status, amount, comment) => {
        let mutatedAmount = (status === 'REJECTED') ? 0 : amount && +amount.split("£ ")[1]
        setLoad(true)
        updateRequestStatus(currentRequest.id, status, mutatedAmount , comment)
            .then(res => {
                setLoad(false)
                setModal(!modal);
                const filteredFilters = Object.fromEntries(
                    Object.entries(filters).filter(([_, value]) => value !== undefined)
                );

                if (Object.keys(filteredFilters).length > 0) {
                    getUplifts(currentPage, filteredFilters);
                }
            })
    }

    const changeOperativeNameFilter = (text) => {
        setOperativeNameFilter(text)
    }

    const changeProjectNameFilter = (text) => {
        setProjectNameFilter(text)
    }

    const changeProjectManagerFilter = (text) => {
        setProjectManagerFilter(text)
    }

    const _renderTableFilters = useCallback(() => {
        return <div>
            <h5 className="page-title m-0 mb-2 mb-md-2">Filters</h5>
            <Row md={12} className={'m-0 mb-md-2 align-items-end'}>
                <Col md={3} className="d-flex justify-content-between">
                    <div className="form__form-group-field">
                        <div className="search_input">
                            <SearchIcon />
                            <input
                                type="text"
                                name="search"
                                placeholder="Search Operative"
                                className="search_input"
                                value={operativeNameFilter}
                                onChange={(data) => {
                                    changeOperativeNameFilter(data.target.value);
                                }}
                                onKeyPress={(e) => {
                                    e.key === "Enter" && e.preventDefault();
                                }}
                            />
                        </div>
                    </div>
                </Col>
                <Col md={3} className="d-flex justify-content-between">
                    <div className="form__form-group-field">
                        <div className="search_input">
                            <SearchIcon />
                            <input
                                type="text"
                                name="search"
                                placeholder="Search Project"
                                className="search_input"
                                value={projectNameFilter}
                                onChange={(data) => {
                                    changeProjectNameFilter(data.target.value);
                                }}
                                onKeyPress={(e) => {
                                    e.key === "Enter" && e.preventDefault();
                                }}
                            />
                        </div>
                    </div>
                </Col>
                <Col md={3} className="d-flex justify-content-between">
                    <div className="form__form-group-field">
                        <div className="search_input">
                            <SearchIcon />
                            <input
                                type="text"
                                name="search"
                                placeholder="Search Project Manager"
                                className="search_input"
                                value={projectManagerFilter}
                                onChange={(data) => {
                                    changeProjectManagerFilter(data.target.value);
                                }}
                                onKeyPress={(e) => {
                                    e.key === "Enter" && e.preventDefault();
                                }}
                            />
                        </div>
                    </div>
                </Col>
            </Row>

        </div>
    },[operativeNameFilter, projectNameFilter, projectManagerFilter, changeOperativeNameFilter, changeProjectNameFilter, changeProjectManagerFilter])

    useEffect(() => {
        if(sortFilter){
            if(sortFilter === 'id_desc'){
                const tempFilters = {...filters, orderBy: 'jobs.id',dateOrder: 'DESC' }
                setFilters(tempFilters)
            }else if(sortFilter === 'id_asc'){
                const tempFilters = {...filters, orderBy: 'jobs.id',dateOrder: 'ASC' }
                setFilters(tempFilters)
            }else if(sortFilter === 'operative_desc'){
                const tempFilters = {...filters, orderBy: 'employee.surname',dateOrder: 'DESC' }
                setFilters(tempFilters)
            }else if(sortFilter === 'operative_asc'){
                const tempFilters = {...filters, orderBy: 'employee.surname',dateOrder: 'ASC' }
                setFilters(tempFilters)
            }else if(sortFilter === 'jobName_desc'){
                const tempFilters = {...filters, orderBy: 'jobs.description',dateOrder: 'DESC' }
                setFilters(tempFilters)
            }else if(sortFilter === 'jobName_asc'){
                const tempFilters = {...filters, orderBy: 'jobs.description',dateOrder: 'ASC' }
                setFilters(tempFilters)
            }else if(sortFilter === 'projectName_desc'){
                const tempFilters = {...filters, orderBy: 'project.name',dateOrder: 'DESC' }
                setFilters(tempFilters)
            }else if(sortFilter === 'projectName_asc'){
                const tempFilters = {...filters, orderBy: 'project.name',dateOrder: 'ASC' }
                setFilters(tempFilters)
            }else if(sortFilter === 'projectManager_desc'){
                const tempFilters = {...filters, orderBy: 'pm.surname',dateOrder: 'DESC' }
                setFilters(tempFilters)
            }else if(sortFilter === 'projectManager_asc'){
                const tempFilters = {...filters, orderBy: 'pm.surname',dateOrder: 'ASC' }
                setFilters(tempFilters)
            } else {
                const tempFilters = {...filters, orderBy: 'jobs.id',dateOrder: 'DESC' }
                setFilters(tempFilters)
            }
        }
    }, [sortFilter])

    useEffect(() => {
        getUplifts(0);
    }, []);

    useEffect(() => {
        setCurrentPage(0)
        // Filter out properties with undefined values
        const filteredFilters = Object.fromEntries(
            Object.entries(filters).filter(([_, value]) => value !== undefined)
        );

        if (Object.keys(filteredFilters).length > 0) {
            getUplifts(0, filteredFilters);
        }
    }, [filters])

    const getUplifts = (page, filters) => {
        setLoading(true);

        getAdditionalWorkRequests(0, filters ? filters : undefined, page ? page : 0).then((res) => {
            if(res && res.items){
                setCurrentUplifts(res.items);
                setTotalPage(Math.ceil(res.total / 10));
            }
        }).catch(e =>  console.log("Error: ", e));
        setLoading(false);
    }

    const idHeaderClicked = useCallback(() => {
        if(sortFilter && sortFilter === 'id_desc') {
            setSortFilter('id_asc')
        } else {
            setSortFilter('id_desc')
        }
    },[setSortFilter, sortFilter])

    const jobNameHeaderClicked = useCallback(() => {
        if(sortFilter && sortFilter === 'jobName_desc') {
            setSortFilter('jobName_asc')
        } else {
            setSortFilter('jobName_desc')
        }
    },[setSortFilter, sortFilter])

    const operativeHeaderClicked = useCallback(() => {
        if(sortFilter && sortFilter === 'operative_desc') {
            setSortFilter('operative_asc')
        } else {
            setSortFilter('operative_desc')
        }
    },[setSortFilter, sortFilter])

    const projectNameHeaderClicked = useCallback(() => {
        if(sortFilter && sortFilter === 'projectName_desc') {
            setSortFilter('projectName_asc')
        } else {
            setSortFilter('projectName_desc')
        }
    },[setSortFilter, sortFilter])

    const projectManagerHeaderClicked = useCallback(() => {
        if(sortFilter && sortFilter === 'projectManager_desc') {
            setSortFilter('projectManager_asc')
        } else {
            setSortFilter('projectManager_desc')
        }
    },[setSortFilter, sortFilter]);

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            if(!!operativeNameFilter){
                // Set the Filters
                const tempFilters = {...filters, operative_name: operativeNameFilter, }
                setFilters(tempFilters)
            }else {
                const tempFilters = {...filters, operative_name: undefined, }
                setFilters(tempFilters)
            }
        }, 800); // Debounce delay of 2 seconds (2000 milliseconds)

        // Clear the debounce timer on component unmount or when textInput changes
        return () => clearTimeout(debounceTimer);
    }, [operativeNameFilter]);

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            if(!!projectNameFilter){
                // Set the Filters
                const tempFilters = {...filters, project_name: projectNameFilter, }
                setFilters(tempFilters)
            }else {
                const tempFilters = {...filters, project_name: undefined, }
                setFilters(tempFilters)
            }
        }, 800); // Debounce delay of 2 seconds (2000 milliseconds)

        // Clear the debounce timer on component unmount or when textInput changes
        return () => clearTimeout(debounceTimer);
    }, [projectNameFilter]);

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            if(!!projectManagerFilter){
                // Set the Filters
                const tempFilters = {...filters, project_manager: projectManagerFilter, }
                setFilters(tempFilters)
                console.log(tempFilters);
            }else {
                const tempFilters = {...filters, project_manager: undefined, }
                setFilters(tempFilters)
                console.log(tempFilters);
            }
        }, 800); // Debounce delay of 2 seconds (2000 milliseconds)

        // Clear the debounce timer on component unmount or when textInput changes
        return () => clearTimeout(debounceTimer);
    }, [projectManagerFilter]);

    const _renderTableHeader = useCallback(() => {
        return <thead>
            <tr>
                <th onClick={() => idHeaderClicked()}>ID <SortOrderIcon size={24} /></th>
                <th>TRADE</th>
                <th onClick={() => jobNameHeaderClicked()}>NAME <SortOrderIcon size={24} /></th>
                <th onClick={() => operativeHeaderClicked()}>OPERATIVE <SortOrderIcon size={24} /></th>
                <th onClick={() => projectNameHeaderClicked()}>PROJECT NAME <SortOrderIcon size={24} /></th>
                <th onClick={() => projectManagerHeaderClicked()}>PROJECT MANAGER <SortOrderIcon size={24} /></th>
                <th>BUDGET</th>
                <th>JOB RATE</th>
                <th>BLOCK</th>
                <th>LEVEL</th>
                <th>UNIT TYPE</th>
                <th>UNIT ID</th>
                <th></th>
            </tr>
        </thead>
    }, [idHeaderClicked, jobNameHeaderClicked, operativeHeaderClicked, projectManagerHeaderClicked, projectNameHeaderClicked]);

    const _renderTableBody = useCallback(() => {
        return <tbody>
        {currentUplifts &&
            currentUplifts.map((item, index) => {
                    const fullUrl = '#'; //PREVIEW_JOB.replace(':project_id', item.project.id).replace(':id', item.id)
                    return <tr key={index} onClick={() => toggleModal(item)}>
                        <td>
                            <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                                {item.jobs && item.jobs.id}
                            </a>
                        </td>
                        <td>
                            <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                                {item.jobs && WORK_TYPE[item.jobs.job_type].bigIcon}
                            </a>
                        </td>
                        <td>
                            <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                                {item.jobs && item.jobs.description}
                            </a>
                        </td>
                        <td>
                            <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                                {item.jobs && item.jobs.employee && item.jobs.employee.first_name + " " + item.jobs.employee.surname}
                            </a>
                        </td>
                        <td>
                            <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                                {item.jobs && item.jobs.project && item.jobs.project.name}
                            </a>
                        </td>
                        <td>
                            <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                                {item.jobs && item.jobs.pm && item.jobs.pm.first_name + " " + item.jobs.pm.surname}
                            </a>
                        </td>
                        <td>
                            <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                                {item && item.budget}
                            </a>
                        </td>
                        <td>
                            <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                                {(item.jobs && item.jobs.budget) ? item.jobs.budget : item.jobs.rate}
                            </a>
                        </td>
                        <td>
                            <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                                {item && item.jobs.block.name}
                            </a>
                        </td>
                        <td>
                            <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                                {item.jobs && item.jobs.level.name}
                            </a>
                        </td>
                        <td>
                            <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                                {item.jobs && item.jobs.unit_type}
                            </a>
                        </td>
                        <td>
                            <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                                {item.jobs && item.jobs.plot.name}
                            </a>
                        </td>
                        <td>
                            <img height="30px" alt={item.jobs.description} src={'https://prod-job-assets.s3.eu-west-2.amazonaws.com/jobs/' + item.jobs.id + '/additional-work/' + item.assets[0].source} onClick={(e) => {
                                e.stopPropagation();
                                setupPhotoViewer(item);
                                setViewerIsOpen(true);
                            }}/>
                        </td>
                    </tr>
                }

            )}
        </tbody>
    }, [currentUplifts]);

    const _renderNoContent = useCallback(() => {
        return <div className="w-100 d-flex justify-content-center">
            <p>No content</p>
        </div>
    },[]);

    const _renderTable = useCallback(() => {
        return <Row>
            <Card>
                <CardBody>
                    {_renderTableFilters()}
                    {currentUplifts && currentUplifts.length > 0 ? <Table responsive
                                                                            hover
                                                                            className="mt-1 table react-table table--bordered pl-0">

                        {_renderTableHeader()}
                        {_renderTableBody()}
                    </Table> : _renderNoContent()}

                </CardBody>
            </Card>

        </Row>
    }, [_renderTableFilters, currentUplifts, _renderTableHeader, _renderTableBody, _renderNoContent]);

    const onChangePage = useCallback((page) => {
        setCurrentPage(page)
        getUplifts(page,filters);
    },[setCurrentPage,getUplifts])

    const _renderPagination = useCallback(() => {
        if(totalPage >= 2){
            return <div className="box-pagination">
                <Pagination
                    count={totalPage}
                    page={currentPage + 1}
                    onChange={(event, page) => onChangePage(page - 1)}
                />
            </div>
        }else {return null}
    },[totalPage,currentPage, onChangePage])

    const _renderContent = useCallback(() => {
        return <div>
            {_renderTable()}
            {_renderPagination()}
        </div>
    },[_renderTable, _renderPagination])

    return (
        <>
            <Container>
                <Row className="pr-3 d-flex justify-content-between">
                    <Col md={5} xl={5} className="d-flex align-items-center mb-4">
                        <Col md={1.5} xl={1.5} className="p-0">
                            <h5 className="page-title m-0">Uplifts Approval</h5>
                        </Col>
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        {loading ? <Loader/>: _renderContent()}
                    </CardBody>
                </Card>
            </Container>

            <PhotoViewer
                closeLightbox={closeLightbox}
                currentImage={currentImage}
                photos={photos}
                viewerIsOpen={viewerIsOpen}
            />

            {modal && <Modal
                isOpen={modal}
                toggle={toggleModal}
                modalClassName={`ltr-support`}
                className={`modal-dialog--primary additional_work-modal`}
            >
                <div className="modal__header">
                    <h3 className="page-title">Additional budget request</h3>
                    <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal} />
                </div>
                <div className="modal__body text-left mt-5">
                    <AdditionalWorkForm updateRequest={updateRequest} currentRequest={currentRequest} assets={assets} setModal={setModal} load={load} />
                </div>
            </Modal>}
        </>
    )
}

export default withRouter(Uplifts);
