import React, {useCallback, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import {Col, Row, Container, CardBody, Card, Table,} from 'reactstrap';
import {JOB_STATUS, PROJECT_STRUCTURE, WORK_TYPE} from "../../../constants/enamerations";
import {getJobsByProject} from "../../../redux/services/jobs";
import {pageSize} from "../../../config/pagination";
import Loader from "../../../shared/components/Loader";
import Pagination from "@material-ui/lab/Pagination";
import {formatPrice} from "../../../helpers/function";
import {PREVIEW_JOB} from "../../../constants/routes";
import {SortOrderIcon} from "../../../constants/icons";

const Jobs = () => {
    const [currentJobs,setCurrentJobs] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPage, setTotalPage] = useState(1);
    const [filters, setFilters] = useState({
        search: '',
        structure: {
            target_ids: [0],
            target_type: PROJECT_STRUCTURE.PROJECT,
        },
        orderBy:"job.id",
        dateOrder:"DESC",
        job_status:JOB_STATUS.ASSIGNED_TO_PM.value,
        job_type: "",
        unit_type: "",
    });
    const [sortFilter, setSortFilter] = useState()


    useEffect(() => {
        if(sortFilter){
            if(sortFilter === 'id_desc'){
                const tempFilters = {...filters, orderBy: 'job.id',dateOrder: 'DESC' }
                setFilters(tempFilters)
            }else if(sortFilter === 'id_asc'){
                const tempFilters = {...filters, orderBy: 'job.id',dateOrder: 'ASC' }
                setFilters(tempFilters)
            }else if(sortFilter === 'description_desc'){
                const tempFilters = {...filters, orderBy: 'job.description',dateOrder: 'DESC' }
                setFilters(tempFilters)
            }else if(sortFilter === 'description_asc'){
                const tempFilters = {...filters, orderBy: 'job.description',dateOrder: 'ASC' }
                setFilters(tempFilters)
            }else if(sortFilter === 'projectName_desc'){
                const tempFilters = {...filters, orderBy: 'project.name',dateOrder: 'DESC' }
                setFilters(tempFilters)
            }else if(sortFilter === 'projectName_asc'){
                const tempFilters = {...filters, orderBy: 'project.name',dateOrder: 'ASC' }
                setFilters(tempFilters)
            }else if(sortFilter === 'blockName_desc'){
                const tempFilters = {...filters, orderBy: 'block.name',dateOrder: 'DESC' }
                setFilters(tempFilters)
            }else if(sortFilter === 'blockName_asc'){
                const tempFilters = {...filters, orderBy: 'block.name',dateOrder: 'ASC' }
                setFilters(tempFilters)
            }else if(sortFilter === 'levelName_desc'){
                const tempFilters = {...filters, orderBy: 'level.name',dateOrder: 'DESC' }
                setFilters(tempFilters)
            }else if(sortFilter === 'levelName_asc'){
                const tempFilters = {...filters, orderBy: 'level.name',dateOrder: 'ASC' }
                setFilters(tempFilters)
            }else if(sortFilter === 'unitId_desc'){
                const tempFilters = {...filters, orderBy: 'plot.name',dateOrder: 'DESC' }
                setFilters(tempFilters)
            }else{
                const tempFilters = {...filters, orderBy: 'plot.name',dateOrder: 'ASC' }
                setFilters(tempFilters)
            }
        }
    }, [sortFilter])

    useEffect(() => {
        setCurrentPage(0)
        // Filter out properties with undefined values
        const filteredFilters = Object.fromEntries(
            Object.entries(filters).filter(([_, value]) => value !== undefined)
        );

        if (Object.keys(filteredFilters).length > 0) {
            getJobsByProjectApi(0, filteredFilters);
        }
    }, [filters])


    const getJobsByProjectApi = (page, filter) => {
        setLoading(true)
        getJobsByProject({page: page && page, pageSize: pageSize && pageSize,}, filter)
            .then((res) => {
                if(res && res.items){
                    setCurrentJobs(res.items);
                    setTotalPage(Math.ceil(res.total / 10))
                }
            }).catch(e =>  console.log("Error: ", e))
        setLoading(false);
    };

    const onChangePage = useCallback((page) => {
        setCurrentPage(page)
        getJobsByProjectApi(page,filters);
    },[setCurrentPage,getJobsByProjectApi])


    const idHeaderFilterClicked = useCallback(() => {
        if(sortFilter && sortFilter === 'id_desc') {
            setSortFilter('id_asc')
        } else {
            setSortFilter('id_desc')
        }
    },[setSortFilter, sortFilter])

    const descriptionHeaderFilterClicked = useCallback(() => {
        if(sortFilter && sortFilter === 'description_desc') {
            setSortFilter('description_asc')
        } else {
            setSortFilter('description_desc')
        }
    },[setSortFilter, sortFilter])

    const operativeNameHeaderFilterClicked = useCallback(() => {
        if(sortFilter && sortFilter === 'operativeName_desc') {
            setSortFilter('operativeName_asc')
        } else {
            setSortFilter('operativeName_desc')
        }
    },[setSortFilter, sortFilter])

    const managerNameHeaderFilterClicked = useCallback(() => {
        if(sortFilter && sortFilter === 'managerName_desc') {
            setSortFilter('managerName_asc')
        } else {
            setSortFilter('managerName_desc')
        }
    },[setSortFilter, sortFilter])

    const projectNameHeaderFilterClicked = useCallback(() => {
        if(sortFilter && sortFilter === 'projectName_desc') {
            setSortFilter('projectName_asc')
        } else {
            setSortFilter('projectName_desc')
        }
    },[setSortFilter, sortFilter])

    const blockNameHeaderFilterClicked = useCallback(() => {
        if(sortFilter && sortFilter === 'blockName_desc') {
            setSortFilter('blockName_asc')
        } else {
            setSortFilter('blockName_desc')
        }
    },[setSortFilter, sortFilter])

    const levelNameHeaderFilterClicked = useCallback(() => {
        if(sortFilter && sortFilter === 'levelName_desc') {
            setSortFilter('levelName_asc')
        } else {
            setSortFilter('levelName_desc')
        }
    },[setSortFilter, sortFilter])

    const unitIdHeaderFilterClicked = useCallback(() => {
        if(sortFilter && sortFilter === 'unitId_desc') {
            setSortFilter('unitId_asc')
        } else {
            setSortFilter('unitId_desc')
        }
    },[setSortFilter, sortFilter])


    const _renderNoContent = useCallback(() => {
        return <div className="w-100 d-flex justify-content-center">
            <p>No content</p>
        </div>
    },[])

    const _renderTableHeader = useCallback(() => {
        return  <thead>
        <tr>
            <th onClick={() => idHeaderFilterClicked()}>ID <SortOrderIcon size={24}/> </th>
            <th>TRADE</th>
            <th onClick={() => descriptionHeaderFilterClicked()}>JOB NAME <SortOrderIcon size={24}/> </th>
            <th onClick={() => operativeNameHeaderFilterClicked()}>OPERATIVE NAME <SortOrderIcon size={24}/> </th>
            <th onClick={() => managerNameHeaderFilterClicked()}>MANAGER NAME <SortOrderIcon size={24}/> </th>
            <th onClick={() => projectNameHeaderFilterClicked()}>PROJECT NAME <SortOrderIcon size={24}/> </th>
            <th>QTY</th>
            <th onClick={() => blockNameHeaderFilterClicked()}>BLOCK <SortOrderIcon size={24}/> </th>
            <th onClick={() => levelNameHeaderFilterClicked()}>LEVEL <SortOrderIcon size={24}/> </th>
            <th>UNIT TYPE</th>
            <th onClick={() => unitIdHeaderFilterClicked()}>UNIT ID <SortOrderIcon size={24}/> </th>
            <th>RATE</th>
        </tr>
        </thead>
    },[idHeaderFilterClicked,
        descriptionHeaderFilterClicked,
        operativeNameHeaderFilterClicked,
        managerNameHeaderFilterClicked,
        projectNameHeaderFilterClicked,
        descriptionHeaderFilterClicked,
        levelNameHeaderFilterClicked,
        unitIdHeaderFilterClicked ])

    const _renderTableBody = useCallback(() => {
        if(currentJobs){
            return  <tbody>
            {currentJobs.map((item, index) => {
                const fullUrl = PREVIEW_JOB.replace(':project_id', item.project_id).replace(':id', item.id)
                const operativeFirstName = item.employee && item.employee.first_name ? item.employee.first_name  : ''
                const operativeLastName = item.employee && item.employee.surname ? item.employee.surname  : ''
                const pmFirstName = item.pm && item.pm.first_name ? item.pm.first_name  : ''
                const pmLastName = item.pm && item.pm.surname ? item.pm.surname  : ''
                const operativeName = `${operativeFirstName} ${operativeLastName}`.trim()
                const pmName = `${pmFirstName} ${pmLastName}`.trim()
                return <tr key={index}>
                    <td style={{minWidth: 60, maxWidth: 60}}>
                        <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>{item.id && item.id}</a></td>
                    <td style={{minWidth: 140, maxWidth: 180}}>
                        <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                            {item.job_type && WORK_TYPE[item.job_type].icon} &nbsp;{" "}
                            {item.job_type && WORK_TYPE[item.job_type].label}
                        </a>
                    </td>
                    <td style={{minWidth: 180, maxWidth: 180}}>
                        <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                            {item.description && item.description}
                        </a>
                    </td>
                    <td style={{minWidth: 180, maxWidth: 180}}>
                        <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                            {`${operativeName && operativeName}`}
                        </a>
                    </td>
                    <td style={{minWidth: 180, maxWidth: 180}}>
                        <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                            {`${pmName && pmName}`}
                        </a>
                    </td>
                    <td style={{minWidth: 200, maxWidth: 200}}>
                        <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                        {item.project && item.project.name}
                    </a></td>
                    <td><a href={fullUrl} className={'table-link'} style={{color: 'black'}}>{item.qty && item.qty}</a></td>
                    <td>
                        <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                            {item.block && item.block.name}
                        </a>
                    </td>
                    <td>
                        <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                            {item.level && item.level.name}
                        </a>
                    </td>
                    <td>
                        <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                            {item.unit_type && item.unit_type}
                        </a>
                    </td>
                    <td>
                        <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                            {item.plot && item.plot.name}
                        </a>
                    </td>
                    <td style={{minWidth: 80, maxWidth: 80}}>
                        <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                            {item.rate && formatPrice(item.rate)}
                        </a>
                    </td>
                </tr>

            })}
            </tbody>
        }else {
            return  null
        }

    },[currentJobs])

    const _renderTable = useCallback(() => {
        return <Row>
            <Card>
                <CardBody>
                    {/*{_renderTableFilters()}*/}
                    {currentJobs && currentJobs.length > 0 ? <Table responsive hover className="mt-1 table react-table table--bordered pl-0" style={{overflowY: 'auto'}}>
                        {_renderTableHeader()}
                        {_renderTableBody()}
                    </Table> : _renderNoContent()}

                </CardBody>
            </Card>

        </Row>
    },[_renderTableHeader,_renderTableBody, currentJobs,_renderNoContent])

    const _renderPagination = useCallback(() => {
        if(totalPage >= 2){
            return <div className="box-pagination">
                <Pagination
                    count={totalPage}
                    page={currentPage + 1}
                    onChange={(event, page) => onChangePage(page - 1)}
                />
            </div>
        }else {return null}
    },[totalPage,currentPage,onChangePage ])

    const _renderContent = useCallback(() => {
        return <div>
            {_renderTable()}
            {_renderPagination()}
        </div>
    },[_renderTable, _renderPagination])

    return (
        <Container>
            <Row className="pr-3 d-flex justify-content-between">
                <Col md={5} xl={5} className="d-flex align-items-center mb-4">
                    <Col md={1.5} xl={1.5} className="p-0">
                        <h5 className="page-title m-0">Jobs Approval - {JOB_STATUS.ASSIGNED_TO_PM.icon} {" "}{JOB_STATUS.ASSIGNED_TO_PM.label}</h5>
                    </Col>
                </Col>
            </Row>
            <Card>
                <CardBody>
                    {loading ? <Loader/>: _renderContent()}
                </CardBody>
            </Card>
        </Container>
    )
}

export default withRouter(Jobs);
