import React, { useState, useEffect } from "react";
import { BuildIcon } from "../../../constants/icons";
import { STEP_STATUS } from "../../../constants/enamerations";

const ThumbnailImage = ({ image }) => (
    <img
        alt={`thumbnail-${image.index}`}
        src={image.public_url}
        style={{ marginRight: image.index !== 0 ? 10 : 0, maxWidth: "50px" }}
    />
);

const StepCardPrint = ({ job }) => {
  const [steps, setSteps] = useState(null);

  useEffect(() => {
    job && setSteps(job.steps);
  }, [job]);

  return (
    steps &&
    steps.map((step, index) =>
        <tr key={index}>
          <td>
            <div style={{borderLeft: `4px solid ${step.status && STEP_STATUS[step.status].color}`,}}
                 className="question_card_wrapper">
              <div className="left_container">
                <h5 style={{fontWeight:'bold'}}>STEP {index + 1}</h5>
                {step.text &&  <p className={"question_info"}>{step.text}</p>}
                {step.status && <div className={"question_status"}>
                  {STEP_STATUS[step.status].icon}&nbsp;{" "}
                  {STEP_STATUS[step.status].label}
                </div>}
              </div>
              <div className={'right_container'}>
                <div className={'image_container'}>
                  {step.assets.slice(0,3).map((image, imageIndex) => {
                    return< ThumbnailImage
                        key={`thumbnail-${imageIndex}`}
                        image={{ ...image, index: imageIndex }}
                    />
                  })}
                </div>
                <div className={'comment_container'}>
                  <p>{step.operative_comment ? step.operative_comment : ""}</p>
                </div>
              </div>
            </div>
          </td>
        </tr>
    )
  );
};

const OperativeForPrint = ({ job }) => {
  return (
    <>
      <div className="print_wrapper w-100">
        <div className="print_header">
          <div className="logo_header">
            <img
              className="pdfLogoimage"
              src={require("../../../shared/img/logo/pdfLogo.png")}
              alt={"logo"}
            />
          </div>
          <div className="contact_header">
            <p>020 3909 8880</p>
            <p>info@londonmep.co.uk</p>
          </div>
        </div>
        <div className="body_wrapper">
          <div className="project_build_wrapper">
            <div className="project_info">
              <div className="d-flex align-items-center">
                <BuildIcon /> &nbsp; {job && job.project.name}
              </div>
              <h1>{job && job.description}</h1>
            </div>
            <div className="build_info">
              <div className="pr-5 w-50 d-flex flex-column justify-content-between">
                <div className="mb-2 d-flex justify-content-between">
                  <p className="dim">Level</p>
                  <span>{job && job.level.name}</span>
                </div>
                <div className="mt-2 d-flex justify-content-between">
                  <p className="dim">Block</p>
                  <span>{job && job.block.name}</span>
                </div>
              </div>
              <div className="pl-5 w-50 d-flex flex-column justify-content-between">
                <div className="mb-2 d-flex justify-content-between">
                  <p className="dim">Unit type</p>
                  <span>{job && job.unit_type}</span>
                </div>
                <div className="mt-2 d-flex justify-content-between">
                  <p className="dim">Unit ID</p>
                  <span>{job && job.plot.name}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="inspection_wrapper">
            <h3>JOB INFORMATION</h3>

            <div className="inspection_information">
              <div className="inspection_item">
                <div className="mb-3 w-100 d-flex justify-content-between">
                  <p className="dim">Conducted on</p>
                  <span>
                    {job &&
                      job.completed_at &&
                      new Date(job.completed_at).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                  </span>
                </div>
                <div className="w-100 d-flex justify-content-between">
                  <p className="dim">Operative name</p>
                  <span>
                    {job &&
                      job.employee &&
                      job.employee.first_name + " " + job.employee.surname}
                  </span>
                </div>
              </div>
              <div className="inspection_item">
                <div className="mb-3 w-100 d-flex justify-content-between">
                  <p className="dim">Main Contractor/Client</p>
                  <span>{job && job.project && job.project.client}</span>
                </div>
              </div>
            </div>

            <p>
              Please take note that all the information in this document is to
              remain private and confidential.
            </p>
          </div>
        </div>
      </div>

      <table className="w-100">
        <thead>
          <tr>
            <th>
              <br />
            </th>
          </tr>
        </thead>
        <tbody className="w-100">
          <StepCardPrint job={job} />
        </tbody>
      </table>

      <table>
        <thead>
          <tr>
            <th>
              <br />
            </th>
          </tr>
        </thead>
        <tbody>
          {job &&
            job.steps.map(
              (item, index) =>
                item.assets.length !== 0 &&
                item.assets.map((img, index) => (
                  <tr key={index}>
                    <td>
                      <div style={{ padding: `30px 30px` }}>
                        <img src={img.public_url} alt={`#${index}`} />
                      </div>
                    </td>
                  </tr>
                ))
            )}
        </tbody>
      </table>
    </>
  );
};

export default OperativeForPrint;
